/* General layout */
.unit-container {
  padding: 30px;
  background: #f5f5f5; /* Soft gradient */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
}

/* Section Titles */
.category-section {
  margin-bottom: 50px;
}

.category-title {
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 20px;
  color: #263238; /* Dark color for headings */
  text-transform: uppercase;
  border-bottom: 3px solid #007bff; /* Blue border for emphasis */
  padding-bottom: 8px;
  letter-spacing: 2px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}

/* Wing Container */
.wing-container {
  display: flex;
  flex-wrap: wrap;
  gap: 25px;
  margin-bottom: 30px;
  justify-content: center;
}

/* Wing Box (Card Style) */
.wing-box {
  background-color: #ffffff;
  border-radius: 15px;
  text-align: center;
  padding: 25px;
  width: 200px;
  cursor: pointer;
  box-shadow: 0 12px 25px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease, background-color 0.3s ease;
  border: 3px solid transparent;
}

.wing-box.active {
  background-color: #007bff; /* Active wing background */
  color: white;
  border-color: #0056b3; /* Highlighted border for active */
  box-shadow: 0 15px 35px rgba(0, 0, 0, 0.3);
}

.wing-title {
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 10px;
}

.wing-box:hover {
  transform: translateY(-10px); /* Floating effect */
  box-shadow: 0 12px 35px rgba(0, 0, 0, 0.2);
}

/* Floor Box (Card Style) */
.floor-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.floor-box {
  background-color: #f4f6f9;
  padding: 18px;
  cursor: pointer;
  text-align: center;
  border-radius: 12px;
  width: 140px;
  box-shadow: 0 8px 18px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s, transform 0.3s;
}

.floor-box.active {
  background-color: #28a745;
  color: white;
  box-shadow: 0 12px 25px rgba(0, 0, 0, 0.2);
}

.floor-box:hover {
  background-color: #218838;
  box-shadow: 0 12px 30px rgba(0, 0, 0, 0.15);
  transform: scale(1.05); /* Hover effect to scale up */
}

/* Unit Grid (Cards) */
.unit-grid {
  display: grid;
  gap: 15px;
  grid-template-columns: repeat(5, 1fr); /* Default to 5 units per row */
  margin-top: 30px;
  padding: 10px;
  justify-items: center;
}

/* Unit Card */
.unit-cell {
  background-color: #ffffff;
  border: 2px solid #ddd;
  border-radius: 12px;
  padding: 20px;
  text-align: center;
  cursor: pointer;
  font-size: 18px;
  font-weight: 700;
  transition: all 0.3s ease;
  width: 100%;
  max-width: 140px;
  box-shadow: 0 8px 18px rgba(0, 0, 0, 0.1);
}

.unit-cell.occupied {
  background-color: #ffcccc; /* Light red */
  cursor: not-allowed;
}

.unit-cell.vacant {
  background-color: #d4edda; /* Light green */
}

.unit-cell:hover {
  background-color: #f1f1f1;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.15);
  transform: translateY(-5px);
}

/* Responsive Grid Layouts */

/* Small screens (1 unit per row) */
@media (max-width: 600px) {
  .unit-grid {
    grid-template-columns: repeat(1, 1fr); /* 1 unit per row */
  }
}

/* Medium screens (3 units per row) */
@media (max-width: 900px) {
  .unit-grid {
    grid-template-columns: repeat(3, 1fr); /* 3 units per row */
  }
}

/* Large screens (default 10 units per row) */
@media (min-width: 1200px) {
  .unit-grid {
    grid-template-columns: repeat(10, 1fr); /* 10 units per row */
  }
}

/* Animation for elements */
@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Apply fade-in animation to category and unit */
.category-title, .wing-box, .floor-box, .unit-cell {
  animation: fadeInUp 0.5s ease-out;
}

.unit-container {
  animation: fadeInUp 1s ease-out;
}

/* Mobile - Extra customization for small screens */
@media (max-width: 450px) {
  .unit-cell {
    font-size: 14px;
    padding: 10px;
  }

  .wing-box {
    width: 150px;
  }
}


