@import '../styles/Colors.css';

/* Overall Login Page Styling */
.login {
  padding: 70px 0;
  height: calc(100vh - 172px);
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f7f7f7; /* Light background for the page */
  background: linear-gradient(135deg, #007dc4, #1E3A8A);
}

/* Container Styling */
.login .col-12 {
  width: 80%;
  margin: auto;
  background-color: #ffffff; /* White background for the login form */
  color: #333333; /* Dark text color for good readability */
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
  animation: zoomIn 0.7s ease-in-out;
}

@keyframes zoomIn {
  from {
    transform: scale(0.9);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}


/* Login Form Styling */
.login .login-form {
  padding: 60px 40px;
}

.login .login-img {
  animation: fadeInLeft 0.7s ease-in-out;
}

@keyframes fadeInLeft {
  from {
    transform: translateX(-50px);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

.login .login-form {
  animation: fadeInRight 0.7s ease-in-out;
}

@keyframes fadeInRight {
  from {
    transform: translateX(50px);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}
/* Title Styling */
.login .login-form h2 {
  font-size: 28px;
  margin-bottom: 30px;
  font-weight: 600;
  color: #1E3A8A; /* Dark Blue color for the heading */
  text-align: center;
}

/* Form Label Styling */
.login .login-form .forms label {
  font-family: 'Arial', sans-serif;
  font-size: 16px;
  font-weight: 500;
  color: #333333;
  margin-bottom: 8px;
}

/* Form Input Styling */
.login .login-form .forms input {
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 12px;
  font-size: 16px;
  width: 100%;
  margin-bottom: 20px;
  font-family: 'Arial', sans-serif;
  color: #333333;
  transition: all 0.3s ease;
}

/* Input Focus Styling */
.login .login-form .forms input:focus {
  border-color: #2563EB; /* Blue border on focus */
  outline: none;
  box-shadow: 0 0 8px rgba(37, 99, 235, 0.5); /* Soft blue glow */
}

/* Button Styling */
.login .login-form .forms .btn {
  background-color: #2563EB; /* Primary Button Color */
  color: #ffffff; /* White text on button */
  padding: 12px 30px;
  border: none;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  width: 100%;
  margin-top: 10px;
}

.login .login-form .forms button:disabled {
  background-color: #ccc; /* Disable button with gray color */
  cursor: not-allowed;
}

.mobile{
  margin-top: 25px;
}

/* Button Hover Effect */
.login .login-form .forms .btn:hover {
  background-color: #1E3A8A; /* Darker Blue on hover */
}

.label{
  font-weight: bold !important;
}
/* OTP Input Styling */
.otp-inputs input {
  border: 1px solid #ddd;
  margin-right: 8px;
  text-align: center;
  font-size: 20px;
  padding: 10px;
  width: 50px;
  border-radius: 8px;
  transition: all 0.3s ease;
}

.otp-inputs input:focus {
  border-color: #2563EB;
  box-shadow: 0 0 6px rgba(37, 99, 235, 0.5);
}

/* OTP Section Styling */
.otp-section {
  margin-top: 20px;
}

.otp-section label {
  font-size: 16px;
  margin-bottom: 12px;
  color: #333333;
}

/* Main Login Container */
.main-login {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #f7f7f7;
}

/* Phone Input Container */
.phone-style {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

/* Phone Input Styling */
.phone-style input {
  border: 1px solid #ddd;
  padding: 12px;
  font-size: 16px;
  border-radius: 8px;
  width: calc(100% - 50px); /* Adjust width for country flag */
}

/* Phone Input Country Flag Styling */
.PhoneInputCountry {
  width: 40px;
  margin-right: 10px;
  border-radius: 8px;
  background-color: #f0f0f0;
  padding: 8px;
}


