@import '../styles/Colors.css';

/* Core Wrapper for Mobile Menu */
#menuToggle {
  display: block;
  position: relative;
  z-index: 10;
  -webkit-user-select: none;
  user-select: none;
}

/* Links Styling */
#menuToggle a {
  font-family: 'Poppins', sans-serif;
  font-size: 18px;
  font-weight: 500;
  text-decoration: none;
  color: #f5f5f5;
  transition: color 0.3s ease, transform 0.3s ease;
}

#menuToggle a:hover {
  color: black; /* Unique Gold Hover */
  transform: translateX(10px);
}

/* Mobile Menu Visibility */
#menuToggle #menu {
  display: none;
}

#menuToggle #menu.show {
  display: flex !important;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  text-align: left;
}

/* Hidden Checkbox to Control Menu Toggle */
#menuToggle input {
  display: block;
  width: 40px;
  height: 32px;
  position: absolute;
  cursor: pointer;
  opacity: 0;
  z-index: 2;
  -webkit-touch-callout: none;
}

/* Hamburger Icon Design */
#menuToggle span {
  display: block;
  width: 33px;
  height: 4px;
  margin-bottom: 5px;
  position: relative;
  background: #fff;
  border-radius: 3px;
  z-index: 1;
  transform-origin: 4px 0px;
  transition: all 0.4s ease-in-out;
}

#menuToggle span:first-child {
  transform-origin: 0% 0%;
}

#menuToggle span:nth-last-child(2) {
  transform-origin: 0% 100%;
}

/* Animating the Hamburger Icon to X */
#menuToggle input:checked ~ span {
  transform: rotate(45deg) translate(-2px, -1px);
  background: black; /* Gold Icon */
}

#menuToggle input:checked ~ span:nth-last-child(3) {
  opacity: 0;
  transform: rotate(0deg) scale(0.2, 0.2);
}

#menuToggle input:checked ~ span:nth-last-child(2) {
  transform: rotate(-45deg) translate(0, -1px);
}

/* Mobile Menu Container */
#menu {
  position: fixed;
  top: 0;
  right: 0;
  width: 75vw;
  height: 100vh;
  background: linear-gradient(135deg, #007dc4, #1E3A8A); /* Matching Gradient */
  box-shadow: -5px 0 15px rgba(0, 0, 0, 0.5);
  padding: 40px;
  padding-top: 100px;
  transform: translateX(100%);
  transition: transform 0.4s ease-in-out;
}

#menu.show {
  transform: translateX(0);
}

/* Menu Items Styling */
#menu li {
  list-style-type: none;
  margin-bottom: 25px;
  text-align: left;
  padding: 8px 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

#menu li:last-child {
  border: none;
}

/* Login Button Styling */
#menu .btn {
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  padding: 10px 20px;
  background: #FFD700;
  color: #1E3A8A;
  border-radius: 50px;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}

#menu .btn:hover {
  background: #fff;
  color: #1E3A8A;
  transform: scale(1.05);
}

/* Responsive Design */
@media (min-width: 992px) {
  #menuToggle {
    display: none !important;
  }
}
