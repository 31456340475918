@import '../styles/Colors.css';

.cta {
  margin-bottom: -88px;
  z-index: 9;
  position: relative;
}
.cta form {
  background-color: var(--font-color);
  padding: 70px;
  font-family: var(--font-family-light);
  border-radius: 35px;
}
.cta form input, .cta form select {
  background-color: transparent;
  border: 0;
  border-bottom: 1px solid var(--background-color);
  border-radius: 0;
}
.cta form .form-select:focus option {
  color: #000;
}
.cta form input::placeholder, .cta form select::placeholder {
  color: var(--background-color);
}
.cta .form-control:focus, .cta .form-select:focus {
  background-color: transparent;
  color: var(--background-color) !important;
  box-shadow: none;
}
.cta button {
  padding: 6px 30px;
  font-size: 16px;
}


.footer .footer-top {
  border-top: 1px solid gray !important;
  border-bottom: 1px solid gray !important;
}
.footer .footer-top .col-lg-6:last-child {
  border-left: 1px solid gray;
  padding-left: 50px;
}
.footer .footer-top .footer-widget {
  padding: 138px 0 50px 0;
}
.footer .footer-top .footer-logo {
  width: 150px;
  margin-bottom: 20px;
}
.footer .footer-top p {
  font-family: var(--font-family-light);
  color: black;
}
.footer .footer-top .footer-info {
  display: flex;
  justify-content: space-evenly;
}
.footer .footer-top .footer-info .phone, .footer .footer-top .footer-info .email {
  width: 50%;
  display: flex;
  align-items: center;
}
.footer .footer-top .footer-info .phone .icons, .footer .footer-top .footer-info .email .icons {
  width: 50px;
  height: 50px;
  line-height: 50px;
  border-radius: 50%;
  text-align: center;
  background-color: var(--btn-hover-color);
  color: var(--background-color);
  margin-right: 10px;
}
.footer .footer-top .footer-info .phone span, .footer .footer-top .footer-info .email span {
  font-family: var(--font-family-light);
  display: grid;
}
.footer .footer-top .footer-info .phone span:last-child, .footer .footer-top .footer-info .email span:last-child {
  color: var(--font-color);
}
.footer .footer-top .footer-widget h3 {
  font-family: var(--font-family-medium);
  margin-bottom: 20px;
}
.footer .footer-top .footer-widget p {
  font-size: 16px;
  margin-bottom: 30px;
}
.footer form {
  position: relative;
}
.footer form svg {
  position: absolute;
  margin-left: 15px;
}
.footer form input, .footer form select {
  border-radius: 50px;
  padding-left: 40px;
  font-family: var(--font-family-light);
}
.footer form .form-control:focus, .footer form .form-select:focus {
  box-shadow: none;
}
.footer form button {
  position: absolute;
  right: 1px;
  border-radius: 50px;
  border: none;
  font-family: 'GT-Walsheim-Light-Trial';
  padding: 6px 20px;
  font-size: 16px;
}
.footer .footer-bottom {
  padding: 20px 0;
  font-family: var(--font-family-light);
}
.footer .footer-bottom .social-media {
  float: right;
}
.footer .footer-bottom .social-media ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
}
.footer .footer-bottom .social-media ul li {
  width: 50px;
  height: 50px;
  line-height: 50px;
  border-radius: 50%;
  text-align: center;
  background-color: var(--btn-hover-color);
  color: var(--background-color);
  margin-right: 10px;
}
.footer .footer-bottom .social-media ul li a {
  color: var(--background-color);
}

.text-error {
  color: white;
  font-size: 0.875rem; /* Adjust size if needed */
  margin-top: 4px; /* Adds some space below the input field */
}

.copyright a {
  color: #000;
  text-decoration: none;
}
.copyright a:hover {
  color: var(--font-color);
}

@media screen and (max-width: 991px) {
  .cta input, .cta button {
    margin-bottom: 10px;
  }
  .footer .footer-top .col-lg-6:last-child {
    border-left: none;
    padding-left: 12px;
  }
}
@media screen and (max-width: 768px) {
  .footer-bottom {
    justify-content: center;
    display: grid;
    text-align: center;
  }
  .footer .footer-bottom .social-media {
    float: none;
    justify-content: center;
    display: flex;
    margin-top: 10px;
  }
}
/* .footer {
  padding: 75px 0 0;
  background-color: var(--font-color);
  position: relative;
  z-index: 2;
}

.footer-u {
  display: flex;
  padding: 0 20px;
  justify-content: space-between;
}

.dk-footer-box-info {
  width: 60%;
  margin: auto;
  background: var(--background-color);
  padding: 40px;
  z-index: 2;
  border-radius: 0% 0% 10% 10%;
}
.footer-widget {
  width: 33%;
}

.footer-social-link {
  margin-left: 7%;
}

.dk-footer-box-info .footer-social-link ul {
  list-style-type: none;
  padding: 10;
  margin: 0;
}

.dk-footer-box-info .footer-social-link li {
  display: inline-block;
}

.dk-footer-box-info .footer-social-link a {
  display: block;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  text-align: center;
  line-height: 40px;
  background: var(--font-color);
  margin-right: 2vw;
  color: var(--background-color);
}

.footer-social-link a {
  text-decoration: none;
}

.footer-info-text {
  margin: 26px 0 32px;
  color: var(--font-color);
  font-weight: 500;
}

.footer-2 {
  color: var(--background-color);
}

.footer-2 h1 {
  color: var(--background-color);
}

.footer-2 a {
  text-decoration: none;
  color: var(--background-color);
  transition: all 0.3s;
}

.footer-3 {
}

.footer-3 h1 {
  color: var(--background-color);
}

.footer-3 p {
  color: var(--background-color);
}

.dk-footer-form input[type=email] {
  padding: 14px 28px;
  border-radius: 50px;
  background: var(--background-color);
  border: 1px solid var(--background-color);
}

.dk-footer-form button[type=submit] {
  position: absolute;
  left: 77%;
  padding: 0.9% 1%;
  border: none;
  border-top-right-radius: 50%;
  border-bottom-right-radius: 50%;
  background: var(--btn-hover-color);
  color: var(--background-color);
}

.form-control {
  margin-bottom: 20px;
}

.col-md-6 {
  display: flex;
  justify-content: left;
  align-items: center;
  background-color: var(--background-color);
  color: var(--font-color);
  font-weight: 800;
  height: 40px;
}

.col-md-6 span {
  margin-left: 10%;
} */