.group {
    display: flex;
    line-height: 28px;
    align-items: center;
    position: relative;
    max-width: 300px;
   }
   
   .input {
    width: 100%;
    height: 40px;
    line-height: 28px;
    padding: 0 1rem;
    padding-left: 2.5rem;
    border: 2px solid transparent;
    border-radius: 8px;
    outline: none;
    background-color: #f3f3f4;
    color: var(--btn-color);
    transition: .3s ease;
   }
   
   .input::placeholder {
    color: var(--btn-color);
   }

   /* .input:focus, input:hover {
    outline: none;
    border-color: var(--btn-color);
    background-color: #fff;
    box-shadow: 0 0 0 4px rgba(56, 52, 255, 0.1);
   } */
   
   .icon {
    position: absolute;
    left: 1rem;
    fill: var(--btn-color);
    width: 1rem;
    height: 1rem;
   }
   
   .search-input {
    padding: 6px 12px 6px 40px;
   }
   .form-control:focus {
    box-shadow: none;
    border-color: var(--font-color);
   }