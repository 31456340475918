@import '../styles/Colors.css';

.map {
  padding: 100px 0;
  background-color: #f9f9f9;
}

.map iframe {
  border-radius: 12px;
  transition: transform 0.3s ease;
}

.map iframe:hover {
  transform: scale(1.05); /* Slight zoom effect on hover */
}

/* Contact Form Section */
.form {
  padding: 80px 0;
}

.contact-form {
  max-width: 600px;
  width: 100%;
  background-color: var(--font-color);
  padding: 40px;
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  margin: auto;
  transition: box-shadow 0.3s ease;
}

.contact-form:hover {
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.15); /* Hover effect for form */
}

.contact-form label {
  color: var(--background-color);
  font-size: 18px;
  font-family: var(--font-familt-marcellus);
  margin-bottom: 8px;
}

.contact-form input,
.contact-form select {
  font-family: 'Nunito', sans-serif;
  border-radius: 8px;
  border: 1px solid #ddd;
  padding: 15px;
  width: 100%;
  margin-bottom: 20px;
  font-size: 16px;
  background-color: #fff;
  transition: border-color 0.3s ease;
}

.contact-form input:focus,
.contact-form select:focus {
  border-color: var(--primary-color); /* Highlight input borders */
  outline: none;
}

.contact-form .btn-white {
  font-size: 16px;  
  border: none;
  margin: auto;
  display: block;
  padding: 12px 30px;
  border-radius: 35px;
  cursor: pointer;
  text-align: center;
  /* width: 100%; */
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.contact-form .btn-white:hover {
 /* Hover effect for button */
  transform: translateY(-3px); /* Button lift effect */
}

/* Contact Information Section */
.contact-info-section {
  margin-top: 50px;
}

.contact-info-section h1 {
  font-size: 30px;
  font-weight: 700;
  color: var(--primary-color);
  margin-bottom: 20px;
  animation: fadeIn 1s ease-out;
}

.contact-info-section p {
  font-size: 18px;
  line-height: 1.5;
  color: var(--font-color);
}

.contact-info-section a {
  font-family: 'Marcellus', serif;
  font-size: 20px;
  display: flex;
  align-items: center;
  text-decoration: none;
  margin-bottom: 15px;
  color: var(--font-color);
  transition: color 0.3s ease;
}

.contact-info-section a:hover {
  color: var(--primary-color);
}

.contact-info-section a i {
  margin-right: 15px;
  font-size: 22px;
  transition: transform 0.3s ease;
}

.contact-info-section a:hover i {
  transform: scale(1.1); /* Icon enlargement on hover */
}

/* Responsive Design */
@media (max-width: 767px) {
  .contact-form {
    padding: 20px;
  }

  .contact-info-section {
    padding: 20px;
  }

  .contact-info-section h1 {
    font-size: 26px;
  }

  .contact-info-section p {
    font-size: 16px;
  }

  .contact-info-section a {
    font-size: 18px;
  }

  .map iframe {
    width: 100%;
    height: 350px;
  }
}

/* Animations */
@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@keyframes zoomIn {
  0% { transform: scale(0.95); opacity: 0; }
  100% { transform: scale(1); opacity: 1; }
}

/* Adding subtle animations */
.contact-info-section h1 {
  animation: fadeIn 0.8s ease-out;
}

.contact-form .btn-white {
  animation: zoomIn 0.5s ease-in-out;
}

