/* Main Container */
.table-container {
  width: 100%;
  margin: 30px auto;
  padding: 25px;
  background: linear-gradient(135deg, #ffffff, #f0f0f0); /* Soft gradient background */
  border-radius: 12px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1); /* Enhanced shadow for depth */
  color: #333;
  transition: all 0.3s ease;
}

/* Table */
.universal-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 35px;
  text-align: left;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; /* Modern font family */
}

/* Table Header */
.universal-table th {
  padding: 18px 25px;
  background: linear-gradient(135deg, #007dc4, #006bb3); /* Gradient using blue */
  color: white;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
  border-bottom: 3px solid #ddd;
  box-shadow: inset 0 4px 6px rgba(0, 0, 0, 0.1); /* Subtle inset shadow */
}

/* Table Cells */
.universal-table td {
  padding: 14px 20px;
  border: 1px solid #ddd;
  background-color: #fff;
  color: #555;
  transition: background-color 0.3s ease;
}

.universal-table td:hover {
  background-color: #f9f9f9; /* Slight hover effect on rows */
  transform: translateX(5px); /* Slight move effect on hover */
}

/* Pagination */
.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  padding: 10px;
}

.pagination button {
  padding: 12px 22px;
  margin: 0 8px;
  background-color: #007dc4; /* Use the blue color for buttons */
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  transition: all 0.3s ease, transform 0.2s ease;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.pagination button:hover {
  background-color: #006bb3; /* Darker blue on hover */
  transform: scale(1.05); /* Subtle scaling effect */
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15); /* Enhanced shadow on hover */
}

.page-number {
  padding: 10px 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  cursor: pointer;
  background-color: white;
  transition: background-color 0.3s ease;
  font-weight: 600;
}

.page-number.active {
  background-color: #007dc4; /* Use blue for active page number */
  color: white;
}

.page-number:hover {
  background-color: #f1f1f1; /* Light hover effect for page numbers */
}

.ellipsis {
  padding: 10px 20px;
  cursor: default;
}

/* Table Header + Sorting */
.table-h {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.table-h-c {
  flex: 1;
}

/* Show Rows Dropdown */
.show-by.table-h-c {
  display: flex;
  justify-content: flex-end;
}

.show-by select {
  padding: 10px;
  margin-left: 15px;
  border-radius: 8px;
  background-color: #fff;
  color: #333;
  border: 1px solid #ddd;
  font-size: 14px;
  font-weight: 600;
  transition: background-color 0.3s ease;
}

.show-by select:hover {
  background-color: #f9f9f9;
}

.show-by option {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 600;
}

/* Input Field & Button */
.input-field button {
  background-color: #007dc4; /* Use the blue color for buttons */
  color: white;
  padding: 12px 25px;
  border-radius: 8px;
  border: 0;
  font-size: 14px;
  font-weight: 600;
  transition: background-color 0.3s ease;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.input-field button:hover {
  background-color: #006bb3; /* Darker blue on hover */
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.input-field input {
  padding: 10px 18px;
  font-size: 14px;
  font-weight: 600;
  border-radius: 8px;
  border: 1px solid #ddd;
  background-color: #fff;
  color: #333;
  transition: border 0.3s ease;
}

.input-field input:focus {
  border-color: #007dc4; /* Blue border on focus */
  outline: none;
}

/* Table Edit Icon */
.table-edit {
  width: 26px;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.table-edit:hover {
  transform: scale(1.4); /* Enlarged on hover for emphasis */
  color: #006bb3; /* Darker blue for hover effect */
}

.table-view {
  width: 26px;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.table-view:hover {
  transform: scale(1.4); /* Enlarged on hover for emphasis */
  color: #006bb3; /* Darker blue for hover effect */
}

/* Responsive Design */
@media (max-width: 768px) {
  .universal-table th, .universal-table td {
    padding: 12px 15px;
    font-size: 13px;
  }

  .pagination button,
  .page-number {
    padding: 8px 15px;
  }

  .show-by select {
    font-size: 12px;
    padding: 8px;
  }

  .input-field input {
    font-size: 12px;
  }
}


.status-box {
  display: inline-block;
  padding: 8px 16px;
  font-size: 14px;
  font-weight: 600;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  white-space: nowrap;
  background-color: #ffffff;
  color: #333;
  transition: all 0.3s ease;
}

.status-box:hover {
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.status-documentselected {
  background-color: #a8d0e6; /* Soft light blue */
  color: #2c3e50; /* Darker blue for contrast */
}

.status-pickup {
  background-color: #ff9f43; /* Warm orange */
  color: #ffffff; /* White text for contrast */
}

.status-login {
  background-color: #34ace0; /* Soft cyan blue */
  color: #ffffff; /* White text for contrast */
}

.status-query {
  background-color: #ff6b81; /* Soft coral red */
  color: #ffffff; /* White text for contrast */
}

.status-sanction {
  background-color: #4caf50; /* Fresh green */
  color: #ffffff; /* White text for contrast */
}

.status-disburse {
  background-color: #6c5ce7; /* Soft purple */
  color: #ffffff; /* White text for contrast */
}

.status-cancel {
  background-color: #ff3838; /* Bold red */
  color: #ffffff; /* White text for contrast */
}

.status-partPayment {
  background-color: #feca57; /* Soft yellow */
  color: #333; /* Dark text for contrast */
}

.status-completed {
  background-color: #2196F3; /* Soft yellow */
  color: #fff; /* Dark text for contrast */
}

.status-default {
  background-color: #bdc3c7; /* Neutral light gray */
  color: #2c3e50; /* Dark text for better readability */
}

/* Status Filter Dropdown */
.status-filter {
  padding: 10px;
  margin-left: 15px;
  border-radius: 8px;
  background-color: #fff;
  color: #333;
  border: 1px solid #ddd;
  font-size: 14px;
  font-weight: 600;
  transition: background-color 0.3s ease;
}

.status-filter:hover {
  background-color: #f9f9f9;
}

.status-filter option {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 600;
}
