.header {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 19px 20px;
    background-color: #fff;
    border-bottom: 1px solid #ddd;
    position: sticky;
    top: 0;
    z-index: 10;
    box-shadow: 0px 0px 10px 0 #007cc3;
  }
  
  .header__content {
    display: flex;
    align-items: center;
  }
  
  .header__profile {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .header__profile-image {
    width: 40px;
    height: 40px;
    border-radius: 50%; /* Circle shape */
    object-fit: cover; /* Ensures the image fits well */
    border: 2px solid #ddd; /* Optional border for the image */
  }
  
  .header__popup {
    position: absolute;
    top: 60px;
    right: 20px;
    background-color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 15px;
    z-index: 20;
  }
  
  .header__popup-content {
    display: flex;
    flex-direction: column;
  }
  
  .header__popup-content p {
    margin: 5px 0;
    color: #333;
  }
  
  .header__logout-button {
    margin-top: 10px;
    padding: 8px 12px;
    border: none;
    background-color: #ff4d4f;
    color: white;
    font-weight: bold;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .header__logout-button:hover {
    background-color: #ff7875;
  }
  