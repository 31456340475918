.breadcrumbs {
    background-color: var(--font-color);
    color: var(--background-color);
    padding: 70px 0;
    text-align: center;
}
.breadcrumbs h3 {
    color: var(--background-color);
    font-family: var(--font-familt-marcellus);
    font-size: 40px;
    margin-bottom: 20px;
}
.breadcrumbs .breadcrumb {
    margin: 0;
    justify-content: center;
}
.breadcrumbs .breadcrumb li {
    font-family: var(--font-family-nunito);
    color: var(--background-color);
}
.breadcrumbs .breadcrumb li a, .breadcrumb-item+.breadcrumb-item::before {
    color: var(--background-color);
    font-family: var(--font-family-nunito);
    text-decoration: none;
}