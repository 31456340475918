@import '../styles/Colors.css';

.hero-banner {
  padding: 100px 0;
  position: relative;
}
.hero-banner-img {
  text-align: end;
}
.hero-banner-img img {
  width: 363px;
  border-radius: 15px;
}

.features .features-box {
  border-radius: 15px;
  overflow: hidden;
  position: relative;
}
.features .features-box .overlay {
  background-color: var(--btn-color);
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0.5;
}
.features .features-box h5 {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  color: var(--background-color);
  font-size: 24px;
  font-family: var(--font-familt-marcellus);
  text-align: center;
  width: 100%;
  height: 100%;
  align-content: center;
  margin-bottom: 0;
  padding: 0 90px;
}

.about {
  padding: 100px 0 0;
}
/* .about .about-content p {
  width: 75%;
} */

.service {
  padding: 100px 0;
}
.service .service-heading h1 {
  width: 70%;
  margin: auto;
  margin-bottom: 20px;
}
.service .service-img img {
  border-radius: 15px;
}
.service .service-points {
  margin-left: 50px;
}
.service .service-points .point {
  display: flex;
  align-items: center;
}
.service .service-points .point::before {
  content: "\F26F";
  font-family: bootstrap-icons;
  font-size: 30px;
  margin-right: 20px;
  color: var(--font-color);
}
.service .service-points h4 {
  font-family: var(--font-familt-marcellus);
  margin-bottom: 0px;
  padding-bottom: 10px;
}

.contact-info {
  padding: 100px 0;
}
.contact-info .contact-info-content {
  margin-right: 45px;
}
.contact-info .contact-info-content p {
  margin: 0 40px 25px 0;
}
.contact-info img {
  border-radius: 15px;
  margin-top: 20px;
}
.contact-info .contact-info-content .img {
  position: relative;
}
.contact-info .experience {
  position: absolute;
  right: -70px;
  bottom: -70px;
  background-color: var(--font-color);
  padding: 35px 60px;
  border: 7px solid var(--background-color);
  border-radius: 18px;
  text-align: center;
  color: var(--background-color) !important;
}
.contact-info .experience h4 {
  font-family: var(--font-familt-marcellus);
  font-size: var(--font-size-h3);
  color: var(--background-color);
  text-transform: uppercase;
  font-weight: 400;
  margin: 0;
}
.contact-info .experience p {
  margin: 0  !important;
  padding: 0 !important;
  color: var(--background-color);
}
.contact-info-details {
  margin-left: 70px;
}
.contact-info-details img {
  margin-bottom: 20px;
}
.certifed-team, .trusted-company, .phone-number {
  display: flex;
}
.certifed-team::before {
  content: "\F154";
  font-family: bootstrap-icons;
  font-size: 30px;
  margin-right: 10px;
  color: var(--font-color);  
}
.trusted-company::before {
  content: "\F52F";
  font-family: bootstrap-icons;
  font-size: 30px;
  margin-right: 10px;
  color: var(--font-color);  
}
.certifed-team-content h4, .trusted-company-content h4 {
  font-family: var(--font-familt-marcellus);
  margin-bottom: 0px;
  font-size: var(--font-size-h3);
}
.phone-number::before {
  content: "\F5C1";
  font-family: bootstrap-icons;
  font-size: 40px;
  border: 3px solid;
  height: fit-content;
  padding: 0 10px;
  margin-right: 10px;
  color: var(--font-color);
  border-radius: 50%;  
}
.phone-number h1 {
  margin: 0;
}

.blog {
  padding: 100px 0;
}
.blog-heading {
  margin-bottom: 60px;
}

@media screen and (max-width: 1399px) {
  .features .features-box h5 {
    padding: 0 50px;
  }
  .about .about-content p {
    width: 100%;
  }
  .contact-info-details {
    margin: 0;
  }
}
@media screen and (max-width: 1199px) {
  .features .features-box h5 {
    padding: 0 30px;
  }
  .service .service-points {
    margin: 0;
  }
}
@media screen and (max-width: 991px) {
  .hero-banner-img {
    text-align: center;
    margin-top: 20px;
  }
  .features .features-box {
    margin-bottom: 20px;
  }
  .service .service-heading h1 {
    width: 100%;
  }
  .service .service-points {
    margin-top: 20px;
  }
}
@media screen and (max-width: 768px) {
  p {
    font-family: var(--font-family-light);
    font-size: 16px;
  }
  .btn {
    font-size: 16px;
  }
}