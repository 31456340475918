@import '../styles/Colors.css';

.services {
    padding: 2px 0;
    background: linear-gradient(135deg, #e0eafc, #cfdef3); /* Updated gradient for modern look */
}

.services-heading {
    margin-right: 70px;
    animation: slideInFromLeft 1.2s ease-in-out;
}

.services .services-img {
    position: relative;
    animation: slideInFromRight 1.2s ease-in-out;
}

.services .services-img img {
    border-radius: 25px;
    box-shadow: 0 10px 40px rgba(0, 0, 0, 0.2);
    transition: transform 0.4s ease, box-shadow 0.4s ease;
}

.services .services-img img:hover {
    transform: scale(1.1);
    box-shadow: 0 15px 50px rgba(0, 0, 0, 0.3);
}

.services .experience {
    position: absolute;
    left: -70px;
    bottom: -70px;
    background: linear-gradient(135deg, #ff7b00, #ffbb33); /* Gradient background */
    padding: 80px 40px;
    border: 8px solid var(--primary-color);
    border-radius: 50%;
    text-align: center;
    color: var(--background-color);
    animation: bounceEffect 2s infinite;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
}

.services .experience h4 {
    font-family: 'Marcellus', serif;
    font-size: 26px;
    color: var(--background-color);
    text-transform: uppercase;
    font-weight: 700;
    margin: 0;
}

.services .experience p {
    margin: 0;
    padding: 0;
    color: var(--background-color);
    font-weight: 600;
    font-size: 18px; /* Slightly bigger font for readability */
}

/* Adding a unique background color to the experience section */
.services .experience h4 {
    background: linear-gradient(135deg, #fff, #fff); /* Gradient background for text */
    -webkit-background-clip: text;
    color: transparent;
    font-size: 32px;
    font-weight: 800;
    text-transform: uppercase;
    margin-bottom: 10px;
}

.services .experience p {
    font-size: 18px;
    color: var(--background-color);
    font-weight: 600;
    margin-top: 5px;
}

/* Additional Animation for the Background */
@keyframes bounceEffect {
    0%, 100% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-15px);
    }
}

.service-info {
    padding: 20px 0;
    background: linear-gradient(135deg, #ffffff, #f3f4f6);
}

.service-info-heading h1 {
    font-size: 38px;
    font-weight: 700;
    color: var(--font-color-dark);
    margin-bottom: 30px;
    animation: fadeInDown 1.5s ease;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
}

.service-box {
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
    padding: 60px 30px;
    border-radius: 20px;
    text-align: center;
    height: 100%;
    background: #ffffff;
    transition: transform 0.4s ease, box-shadow 0.4s ease, background 0.3s ease;
    animation: zoomIn 1.2s ease;
}

.service-box:hover {
    transform: translateY(-10px);
    box-shadow: 0 20px 40px rgba(0, 0, 0, 0.2);
    background: linear-gradient(135deg, #ffe9e9, #ffffff); /* Subtle hover gradient */
}

.service-box h3 {
    font-size: 24px;
    font-weight: 700;
    color: var(--primary-color);
    margin-bottom: 15px;
    text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
}

.service-box p {
    margin: 0;
    font-size: 16px;
    color: #6c757d;
    line-height: 1.6;
}

.clients {
    padding: 100px 0;
    background: linear-gradient(135deg, #ffffff, #f9faff);
}

.clients .heading {
    font-size: 38px;
    font-weight: 700;
    color: var(--font-color-dark);
    margin-bottom: 50px;
    animation: fadeInDown 1.5s ease;
}

.clients .client-slider {
    animation: fadeIn 1.5s ease;
}

.blog {
    padding: 20px 0;
    background: #ffffff;
}

.blog-heading {
    margin-bottom: 40px;
    animation: fadeInDown 1.5s ease;
}

.blog-heading .btn {
    background: var(--primary-color);
    color: var(--background-color);
    border-radius: 30px;
    padding: 12px 35px;
    font-size: 18px;
    font-weight: 600;
    transition: background-color 0.4s ease, transform 0.4s ease;
}

.blog-heading .btn:hover {
    background: var(--secondary-color);
    transform: scale(1.1);
}

.blog-heading h1 {
    font-size: 36px;
    font-weight: 700;
    color: var(--font-color-dark);
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
}

/* Responsive Adjustments */
@media screen and (max-width: 991px) {
    .services-heading {
        margin-right: 0;
        text-align: center;
    }

    .services .services-img img {
        margin-top: 30px;
    }

    .services .experience {
        left: 0;
        bottom: -50px;
    }
}

@media screen and (max-width: 767px) {
    .service-box {
        padding: 40px 20px;
    }

    .services-heading h1 {
        font-size: 28px;
    }

    .service-info-heading h1 {
        font-size: 30px;
    }
}

/* Additional Animations */
@keyframes slideInFromLeft {
    from {
        opacity: 0;
        transform: translateX(-100px);
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes slideInFromRight {
    from {
        opacity: 0;
        transform: translateX(100px);
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes zoomIn {
    from {
        opacity: 0;
        transform: scale(0.8);
    }
    to {
        opacity: 1;
        transform: scale(1);
    }
}

@keyframes bounceEffect {
    0%, 100% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-15px);
    }
}
