.owl-carousel.clientslider {
    padding-bottom: 100px;
}
.owl-carousel .item img {
    width: 100px;
    display: block;
    margin: auto;
}
.owl-carousel .owl-stage {
    align-items: center;
    display: flex;
}