/* Ensures styles are applied only to elements within the .blog-home class */
@import '../styles/Colors.css';
.blog-home .blog-box {
    color: var(--background-color);
    border-radius: 15px;
    transition: all 0.4s ease-in-out;
    overflow: hidden;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
    height: 100%;
    display: flex;
    flex-direction: column;
    background: linear-gradient(145deg, #ffffff, #f4f7fc);
}

/* Hover effect for the blog box */
.blog-home .blog-box:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 25px rgba(0, 0, 0, 0.2); /* Stronger shadow on hover */
}

/* Image Styles */
.blog-home .blog-box-img {
    height: 240px;
    overflow: hidden;
}

.blog-home .blog-box-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
}

/* Image Hover Effect */
.blog-home .blog-box-img img:hover {
    transform: scale(1.1); /* Slight zoom on hover */
    opacity: 0.8;
}

/* Content Box Styling */
.blog-home .blog-box-content {
    padding: 25px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

/* Blog Title */
.blog-home .blog-box-content h3 {
    font-family: 'Marcellus', sans-serif;
    font-size: 22px;
    font-weight: 600;
    color: #333;
    margin-bottom: 15px;
    transition: color 0.3s ease;
}

/* Blog Title Hover Effect */
.blog-home .blog-box-content h3:hover {
    color: var(--font-color);
}

/* Blog Paragraph Styling */
.blog-home .blog-box-content p {
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    line-height: 1.6;
    color: #6c757d;
    margin-bottom: 20px;
}

/* Read More Link Styling */
.blog-home .read-more {
    text-decoration: none;
    font-family: 'Marcellus', sans-serif;
    text-transform: uppercase;
    font-weight: 600;
    color: var(--font-color);
    display: inline-flex;
    align-items: center;
    transition: color 0.3s ease;
}

/* Read More Hover Effect */
.blog-home .read-more:hover {
    color: #333;
}

.blog-home .read-more i {
    margin-left: 5px;
    font-size: 18px;
}

/* Grid Layout Styles */
.blog-home .row {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}

.blog-home .col-lg-4,
.blog-home .col-md-6 {
    flex: 1 1 calc(33.3333% - 20px);
    box-sizing: border-box;
}

/* Responsive Styles */
@media (max-width: 991px) {
    .blog-home .col-lg-4 {
        flex: 1 1 calc(50% - 20px);
    }
}

@media (max-width: 767px) {
    .blog-home .col-lg-4 {
        flex: 1 1 100%;
    }
}

@media (max-width: 576px) {
    .blog-home .blog-box-content h3 {
        font-size: 20px; /* Adjust font size on smaller screens */
    }
}
