@import '../styles/Colors.css';

.h-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.h-popup {
  background: var(--font-color);
  padding: 20px;
  border-radius: 8px;
  position: relative;
  width: 300px;
  border: 3px solid black;
}

.clo-btn {
  position: absolute;
  margin-bottom: 10px;
  top: 10px;
  right: 10px;
  background: var(--background-color);
  color: var(--font-color);
  border: none;
  font-size: 18px;
  cursor: pointer;
  font-size: 30px;
  padding: 3px 10px;
  border-radius: 5px;
  justify-content: center;
}

.h-popup-form {
  display: flex;
  flex-direction: column;
  margin-top: 40px;
}

.h-popup-form div {
  margin-bottom: 20px;
}

.h-popup-form label {
  color: var(--background-color);
  font-size: 25px;
}

.h-popup-form ::placeholder {
  color: var(--font-color);
}

.h-popup-form input,
.h-popup-form select {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
  border-radius: 10px;
  font-size: 20px;
  border: 1px solid var(--font-color);
  font-family: var(--font-family);
  color: var(--font-color);
}

.h-popup-form option {
  background-color: var(--background-color);
}

.h-popup-form button {
  padding: 10px;
  background: var(--background-color);
  color: var(--font-color);
  border: none;
  cursor: pointer;
  font-family: var(--font-family);
}

.h-popup-form button:hover {
  background: var(--btn-hover-color);
  color: var(--background-color);
}
