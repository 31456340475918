:root {
    --font-family-nunito: 'Nunito', sans-serif;
    --font-family-marcellus: 'Marcellus', serif;
    --box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}
.contact-info .img {
    position: relative;
    /* overflow: hidden; */
    border-radius: 10px;
    box-shadow: var(--box-shadow);
    transition: transform 0.5s ease;
}
.contact-info .img:hover {
    transform: scale(1.05);
}
.contact-info .experience {
    position: absolute;
    bottom: -40px;
    right: -30px;
    width: 213px;
    height: 118px;
    background: rgba(0, 0, 0, 0.5);
    color: white;
    padding: 15px;
    border-radius: 5px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}

.contact-info-right-content .heading {
    font-size: 32px;
    margin-bottom: 20px;
    color: var(--primary-color);
}

.contact-info-right-content p {
    font-size: 18px;
    line-height: 1.6;
    margin-bottom: 20px;
    color: var(--font-color);
}
.accordion-item {
    border: none;
    padding-bottom: 15px;
    transition: box-shadow 0.3s ease;
}
.accordion-button {
    font-size: var(--font-size-h6);
    font-family: var(--font-familt-marcellus);
    padding: 0;
}
.count .count-box {
    background-color: white;
    padding: 30px;
    border-radius: 10px;
    box-shadow: var(--box-shadow);
    transition: all 0.3s ease;
    margin-bottom: 30px;
}
.count .count-box:hover {
    transform: translateY(-10px);
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
}

.count .count-box h1 {
    margin-bottom: 10px;
    line-height: 36px;
    transition: color 0.3s ease;
}
.accordion-button:not(.collapsed) {
    background-color: transparent;
    box-shadow: none;
    color: var(--font-color);
}
.accordion-body {
    font-family: var(--font-family-nunito);
    padding-top: 10px;
}
.count {
    padding: 100px 0 0;
}
.count .count-box h1 {
    margin-bottom: 10px;
    line-height: 36px;
}
.count .count-box span {
    font-family: var(--font-family-nunito);
}
.count .count-box svg {
    font-size: 40px;
    margin-bottom: 15px;
    color: var(--font-color);
}

@media screen and (max-width: 991px) {
    .contact-info-right-content {
        margin-top: 70px;
    }
    .count .count-box {
        margin-bottom: 20px;
    }
}