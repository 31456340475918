/* @import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@400;600&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200..1000;1,200..1000&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Marcellus&family=Nunito:ital,wght@0,200..1000;1,200..1000&display=swap');
@font-face {
  font-family: GT-Walsheim-Black-Trial;
  src: url('../fonts/GT-Walsheim-Black-Trial.otf');
}
@font-face {
  font-family: GT-Walsheim-Medium-Trial;
  src: url('../fonts/GT-Walsheim-Medium-Trial.otf');
}
@font-face {
  font-family: GT-Walsheim-Regular-Trial;
  src: url('../fonts/GT-Walsheim-Regular-Trial.otf');
}
@font-face {
  font-family: GT-Walsheim-Light-Trial;
  src: url('../fonts/GT-Walsheim-Light-Trial.otf');
}
@font-face {
  font-family: GT-Walsheim-Thin-Trial;
  src: url('../fonts/GT-Walsheim-Thin-Trial.otf');
}

:root {
  --background-color: #fff;
  --btn-hover-color: #76c6f0;
  --btn-color: #196084;
  --font-color: #007dc4;
  --light-background-color: #32c690ab;
  --black: #000;
  --font-family-bold: 'GT-Walsheim-Black-Trial';
  --font-family-medium: 'GT-Walsheim-Medium-Trial';
  --font-family-regular: 'GT-Walsheim-Regular-Trial';
  --font-family-light: 'GT-Walsheim-Light-Trial';
  --font-family-thin: 'GT-Walsheim-Thin-Trial';
  --font-family-nunito: 'Nunito';
  --font-familt-marcellus: 'Marcellus';
  --font-size-base: 16px;
  --font-size-h1: 42px;
  --font-size-h2: 35px;
  --font-size-h3: 24px;
  --font-size-h4: 22px;
  --font-size-h5: 20px;
  --font-size-h6: 18px;
  --font-size-p: 18px;
  

  
}



h1, h2, h3, h4, h5, h6 {
  color: var(--font-color);
}

h1 { font-size: var(--font-size-h1); }
h2 { font-size: var(--font-size-h2); }
h3 { font-size: var(--font-size-h3); }
h4 { font-size: var(--font-size-h4); }
h5 { font-size: var(--font-size-h5); }
h6 { font-size: var(--font-size-h6); }
p { font-size: var(--font-size-p); font-family: var(--font-family-nunito); color: #000; }
ul li { font-size: var(--font-size-p); padding: 0; margin: 0; list-style-type: none; }
/* Base button styles */
.btn {
  color: var(--background-color);
  background-color: var(--font-color);
  font-family: var(--font-familt-marcellus);
  padding: 15px 30px;
  border: none;
  border-radius: 35px;
}

/* Hover styles for buttons */
.btn:hover {
  background-color: var(--btn-hover-color);
  color: var(--background-color);
}

.btn-white {
  background-color: var(--background-color);
  color: var(--font-color);
}

.btn-margin {
  margin-right: 20px;
}

.heading {
  font-size: var(--font-size-h1);
  line-height: normal;
  margin-bottom: 20px;
  font-weight: 400;
  font-family: var(--font-familt-marcellus);
}
h3.heading {
  font-size: var(--font-size-h2);
  line-height: normal;
  margin-bottom: 15px;
  font-weight: 400;
  font-family: var(--font-familt-marcellus);
}
.sidebar.open .page-wrapper{
  margin-left: 250px;
}
.blue-bg {
  background-color: #007cc3;
}
.green-bg {
  background-color: #005e65;
}
.orange-bg {
  background-color: #de411a;
}
.yellow-bg {
  background-color: #f8c300;
}