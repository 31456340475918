.bloglist {
    padding-top: 100px;
}
.bloglist .bloglist-img {
    width: 100%;
    height: 30vw;
    display: block;
}
.bloglist .bloglist-img img {
    width: 100%;
    height: 100%;
}
.bloglist-content {
    padding: 50px 0;
}

.bloglist h1 {
    text-align: center;
}

.bloglist p {
    margin: 20px;
    font-size: 20px;
}