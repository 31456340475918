.toaster-container {
    position: fixed;
    top: 20px;
    right: 20px;
    z-index: 1000;
  }
  
  .toast {
    display: inline-block;
    margin: 10px;
    padding: 15px 20px;
    color: white;
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    opacity: 0;
    animation: fadeInOut 5s forwards;
  }
  
  .toast.success {
    background-color: #38A169;
  }
  
  .toast.error {
    background-color: #D60024;
  }
  
  @keyframes fadeInOut {
    0% {
      opacity: 0;
      transform: translateY(-20px);
    }
    10%, 90% {
      opacity: 1;
      transform: translateY(0);
    }
    100% {
      opacity: 0;
      transform: translateY(-20px);
    }
  }
  